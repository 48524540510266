import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    eoId: false,
    api: 'https://info-sites.mskobr.ru',
    params: '/api/params',
    dataParams: false
  },
  mutations: {
    setEoId (state, value) {
      state.eoId = value
    },
    setDataParams (state, value) {
      state.dataParams = value
    }
  },
  actions: {
    getDataParams () {
      let path = this.state.api + this.state.params + '.json?eoId=' + this.state.eoId
      Axios({
        url: path,
        method: 'GET'
      }).then((res) => {
        if (res.status === 200 && res.data.response === 'success') {
          this.commit('setDataParams', res.data.result)
        }
      })
    }
  }
})
