<template>
  <div v-scroll="handleScroll">
    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12" style="margin-bottom: 25px;">
      <div style="font-size: 20px;">Мы в соцсетях</div>
      <div class="social-block">
        <a v-if="params.social_vk" :href="params.social_vk" class="social-vk" target="_blank"></a>
<!--        <a v-if="params.social_facebook" :href="params.social_facebook" class="social-fb" target="_blank"></a>-->
        <a v-if="params.social_youtube" :href="params.social_youtube" class="social-yt" target="_blank"></a>
<!--        <a v-if="params.social_instagram" :href="params.social_instagram" class="social-inst" target="_blank"></a>-->
<!--        <a v-if="params.social_twitter" :href="params.social_twitter" class="social-tw" target="_blank"></a>-->
        <a v-if="params.social_ok" :href="params.social_ok" class="social-ok" target="_blank"></a>
        <a v-if="params.social_tiktok" :href="params.social_tiktok" class="social-tik" target="_blank"></a>
        <a v-if="params.social_telegram" :href="params.social_telegram" class="social-tm" target="_blank"></a>
        <a v-if="params.yandex_dzen" :href="params.yandex_dzen" class="social-dzen" target="_blank"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'app',
  created () {
    this.$store.commit('setEoId', document.body.getAttribute('data-eo-id'))
  },
  computed: {
    ...mapState({
      params: 'dataParams'
    })
  },
  methods: {
    handleScroll (evt, el) {
      if (
        window.pageYOffset >= el.getBoundingClientRect().top
      ) {
        if (this.params === false) {
          this.$store.dispatch('getDataParams')
        }
      }
    }
  }
}
</script>

<style lang="less">
  .social-block a{
    display: inline-block;
    background-image: url('/img/social_networks_new_v2.png');
    width:50px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 10px
  }
  .social-block a:hover{
    background-position-y: 0;
  }
  .social-vk{ background-position: 466px 50px; }
  //.social-fb{ background-position: 362px 50px; }
  .social-yt{ background-position: 362px 50px; }
  //.social-inst{ background-position: 258px 50px; }
  //.social-tw{ background-position: 206px 50px; }
  .social-ok{ background-position: 206px 50px; }
  .social-tik{ background-position: 152px 50px; }
  .social-tm{ background-position: 102px 50px; }
  .social-dzen{ background-position: 50px 50px; }
</style>
